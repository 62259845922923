<template>
    <transition name="slide-up">
        <div v-show="show" class="sheet">
            <div class="icon-close">
                <img
                    :src="require('@/assets/images/icons/close.png')"
                    @click="close"
                    width="24px"
                    height="24px"
                    alt=""
                />
            </div>
            <div class="main-title m-t-16">프로그램이 곧 만료됩니다!</div>
            <div class="sub-title m-t-4">많은 이성이 기다리고 있으니 놓치지 마세요</div>
            <div class="expire-img-wrapper m-t-8">
                <img :src="require('@/assets/images/img_expiration.png')" alt="" />
            </div>
            <!-- <BottomButton
                v-if="!isNewUser"
                @click="moveToProductList"
                :label="'프로그램 갱신하기'"
                :blackBtn="true"
                class="bottom-button"
            /> -->
        </div>
    </transition>
</template>

<script>
export default {
    name: 'ModalNoticeExpire.',
    props: ['options'],
    data: () => ({
        show: false,
    }),
    computed: {},
    mounted() {
        setTimeout(() => {
            this.show = true
        }, 200)
    },
    methods: {
        close() {
            this.show = false
            setTimeout(() => {
                this.$emit('close')
            }, 400)
        },
        moveToProductList() {
            this.close()
            this.$router.push({ name: 'PrePaymentPage' })
        },
        isNewUser() {
            const me = this.$store.getters.me
            return me.products.some(p => p.product_id >= 19)
        },
    },
}
</script>

<style lang="scss" scoped>
.sheet {
    width: 100vw;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 12px 12px 0 0 !important;
    .icon-close {
        display: flex;
        justify-content: flex-end;
    }
    .main-title {
        font-weight: 700;
        font-size: 24px;
        line-height: 36px;
        color: #111111;
        text-align: center;
    }
    .sub-title {
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        text-align: center;
        color: #111111;
    }
    .expire-img-wrapper {
        width: 100%;
        padding: 0;
        position: relative;
        padding-bottom: 48px;
        img {
            // position: absolute;
            // top: 0;
            position: relative;
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: top;
        }
    }
    .bottom-button {
        padding-top: 0;
    }
}
.slide-up-enter-active,
.slide-up-leave-active {
    transition: all 0.4s ease;
}
.slide-up-enter,
.slide-up-leave-to {
    opacity: 0;
    transform: translateY(40px);
}
</style>
